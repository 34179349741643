import React from "react";
import MotionWrapper from "./MotionWrapper";
import ContactForm from "./Forms/ContactForm";

function Contact() {
  // Define the background image URL
  const backgroundImage = "/02.jpg"; // Path relative to the public folder

  return (
    <MotionWrapper backgroundImage={backgroundImage}>
      <section className="flex w-dvw items-center justify-center">
        <article className="flex max-w-[1920px] flex-col gap-4 bg-white/[.7] p-4 pt-20">
          <header className="flex flex-col items-center justify-center gap-4 border-b border-b-purple-500 p-4 md:flex-row">
            <h1 className="text-3xl text-purple-500 md:text-4xl">
              <strong>Ready to Bring Your Project to Life?</strong>
            </h1>
          </header>
          <section>
            <p>
              We are here to help you achieve your digital and creative goals, whether it is:
            </p>
            <ul className="list-none">
              <li className="text-green-500">Building a website</li>
              <li className="text-teal-500">Creating stunning 3D models</li>
              <li className="text-orange-500">Delivering high-quality prints</li>
            </ul>
            <p>Let&rsquo;s discuss how we can collaborate to make your project a success.</p>
            <p>
              <strong>Based in Southampton, serving the UK, Europe, and Australia.</strong>
            </p>
          </section>
          <section>
            <ContactForm />
          </section>
        </article>
      </section>
    </MotionWrapper>
  );
}

export default Contact;
