import React from 'react';
import MotionWrapper from "./MotionWrapper";
import TechStackChart from './Charts/TechStackChart';
import TechSkillsChart from './Charts/TechSkillsChart';

const StatsPage = () => {
    const backgroundImage = "/04.jpg"; // Path relative to the public folder

    return (
        <MotionWrapper backgroundImage={backgroundImage}>
            <section className="flex w-dvw items-center justify-center">
                <article className="flex max-w-[1920px]  flex-col gap-4 bg-white/[.7] p-4 pt-20 text-center">
                    <header className="flex flex-col items-center justify-center gap-4 border-b border-b-purple-500 p-4 md:flex-row">
                        <h1 className="text-xl text-purple-500 md:text-4xl">Tech Stack and Skills</h1>
                    </header>
                    <section className="flex flex-wrap gap-4 md:flex-row">
                        <article className="flex flex-1 flex-col items-center gap-4">
                            <h2 className="text-xl font-semibold">Percentage Breakdown of Projects by Platform</h2>
                            <div className="flex h-[250px] w-full items-center justify-center">
                                <TechStackChart />
                            </div>
                            <p>The majority of our projects are focused on WordPress and React, with WooCommerce being used in a smaller subset of eCommerce projects.</p>
                        </article>

                        <article className="flex flex-1 flex-col items-center gap-4">
                            <h2 className="text-xl font-semibold">Web Technologies Mastered</h2>
                            <div className="flex h-[250px] w-full items-center justify-center">
                                <TechSkillsChart />
                            </div>
                            <p className="mt-2">We have strong expertise in HTML, CSS, JavaScript, PHP, and React. Our proficiency varies from 6-9 on a scale of 10, with React and HTML being the strongest.</p>
                        </article>
                    </section>
                </article>
            </section>
        </MotionWrapper>
    );
};

export default StatsPage;
