import React from 'react';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Pie } from 'react-chartjs-2';

// Register components
ChartJS.register(ArcElement, Tooltip, Legend);

const CustomerSatisfactionChart = () => {
    const data = {
        labels: ['Positive Reviews', 'Negative Reviews'],
        datasets: [
            {
                label: 'Customer Satisfaction',
                data: [97, 3], // 97% positive, 3% negative reviews
                backgroundColor: ['rgba(75, 192, 192, 0.8)', 'rgba(255, 99, 132, 0.8)'],
                borderColor: ['rgba(75, 192, 192, 1)', 'rgba(255, 99, 132, 1)'],
                borderWidth: 1,
            },
        ],
    };

    const options = {
        responsive: true,
        animation: {
            duration: 1500, // The duration of the animation in milliseconds
            easing: 'easeInOut', // The easing function for animation

        },
        plugins: {
            title: {
                display: true,
                text: 'Customer Satisfaction Score',
            },
            legend: {
                position: 'top',
            },
        },
    };

    return <Pie data={data} options={options} />;
};

export default CustomerSatisfactionChart;
