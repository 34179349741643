import React from "react";
import MotionWrapper from "./MotionWrapper";
import PageLoadSpeedChart from "./Charts/PageLoadSpeedChart";
import ProjectCompletionChart from "./Charts/ProjectCompletionChart";
import CustomerSatisfactionChart from "./Charts/CustomerSatisfactionChart";

function Services() {
  const backgroundImage = "/03.jpg"; // Path relative to the public folder

  return (
    <MotionWrapper backgroundImage={backgroundImage}>
      <section className="flex w-dvw items-center justify-center">
        <article className="flex max-w-[1920px] flex-col gap-4 p-4 pt-20 text-center ">
        
          <section className="flex flex-col p-4 lg:flex-row lg:flex-wrap lg:justify-between">
            <div className="flex flex-col bg-white/[.7] p-4">
              <header className="flex flex-col items-center justify-center gap-4 border-b border-b-purple-500 p-4 md:flex-row">
                <h1 className="text-xl text-purple-500 md:text-4xl">
                  Website Consultancy & Development
                </h1>
              </header>
              <h2 className="text-xl text-teal-500">Serving a Broad Range of Industries</h2>
              <p>
                Our versatile team has experience across multiple industries. Whether you need a website for higher education, product animations for retail, or architectural 3D renders for construction, we have got you covered.
              </p>
              <ul className="p-4 text-left">
                <li>
                  <strong className="text-xl text-teal-500">Education:</strong> Creating digital experiences that improve learning and engagement.
                </li>
                <li>
                  <strong className="text-xl text-green-500">Telecommunications:</strong> Designing digital solutions that enhance customer interaction.
                </li>
                <li>
                  <strong className="text-xl text-purple-500">Retail/eCommerce:</strong> Building immersive eCommerce platforms with seamless functionality.
                </li>
              </ul>
            </div>
          </section>

          <section className="flex flex-col p-4 lg:flex-row lg:flex-wrap lg:justify-between">
            {/* Total Websites Built Section */}
            <article className="flex flex-col bg-white/[.7] p-4">
              <h3 className="text-xl text-purple-500">Total Websites Built</h3>
              <p>
                <ul className="text-left">
                  <li>Average project completion time.</li>
                  <li>Customer satisfaction score: “97% positive reviews”.</li>
                  <li>Percentage of projects completed on time.</li>
                </ul>
              </p>
              <div className="flex h-[250px] items-center justify-center">
                <ProjectCompletionChart />
              </div>
            </article>
            {/* Performance Stats Section */}
            <article className="flex flex-col bg-white/[.7] p-4">
              <h3 className="text-xl text-purple-500">Performance Stats</h3>
              <p>
                <ul className="text-left">
                  <li>Average page load speed for client websites.</li>
                  <li>Bounce rate reduction achieved.</li>
                  <li>SEO improvements: “Average rank improvement: +300 positions”.</li>
                </ul>
              </p>
              <div className="flex h-[250px] items-center justify-center">
                <PageLoadSpeedChart />
              </div>
            </article>

            {/* Client Base Section */}
            <article className="flex flex-1 flex-col bg-white/[.7] p-4">
              <h3 className="text-xl text-purple-500">Client Base</h3>
              <p>
                <ul className="text-left">
                  <li>Number of clients served: 500+ happy clients.</li>
                  <li>Geographical distribution of clients: Global for universities and Cytoplan.</li>
                  <li>Repeat business rate: 60% of clients return for more projects.</li>
                </ul>
              </p>
              <div className="flex h-[250px] items-center justify-center">
                <CustomerSatisfactionChart />
              </div>
            
            </article>
          </section>
        </article>
      </section>
    </MotionWrapper>
  );
}

export default Services;
