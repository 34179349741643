import React, { useState } from "react";

const ContactForm = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    company: "",
    message: "",
  });

  const [formStatus, setFormStatus] = useState("");

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setFormStatus("Sending...");

    try {
      await fetch("https://ridgy-didge.co.uk/contact.php", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formData),
      });
      setFormStatus("Message sent successfully!");
      setFormData({
        name: "",
        email: "",
        phone: "",
        company: "",
        message: "",
      });
    } catch (error) {
      setFormStatus("Error sending message. Please try again later.");
    }
  };

  return (
    <div className="contact-form-container">
      <h1 className="text-3xl text-green-500 md:text-4xl">Get in Touch</h1>
      <p>We&apos;d love to hear about your project. Fill out the form below and we&apos;ll get back to you soon!</p>
      <form onSubmit={handleSubmit} className="contact-form flex flex-col gap-4">
        <div className="form-group flex gap-2">
          <label htmlFor="name">Name *</label>
          <input
            type="text"
            id="name"
            name="name"
            className="flex-1"
            value={formData.name}
            onChange={handleChange}
            required
          />
        </div>
        <div className="form-group flex gap-2">
          <label htmlFor="email">Email *</label>
          <input
            type="email"
            id="email"
            name="email"
            className="flex-1"
            value={formData.email}
            onChange={handleChange}
            required
          />
        </div>
        <div className="form-group flex gap-2">
          <label htmlFor="phone">Phone</label>
          <input
            type="tel"
            id="phone"
            name="phone"
            className="flex-1"
            value={formData.phone}
            onChange={handleChange}
          />
        </div>
        <div className="form-group flex gap-2">
          <label htmlFor="company">Company</label>
          <input
            type="text"
            id="company"
            name="company"
            className="flex-1"
            value={formData.company}
            onChange={handleChange}
          />
        </div>
        <div className="form-group flex gap-2">
          <label htmlFor="message">Message *</label>
          <textarea
            id="message"
            name="message"
            rows="5"
            value={formData.message}
            onChange={handleChange}
            required
          ></textarea>
        </div>
        <button type="submit" className="btn-submit btn-hover-animate">
          Submit
        </button>
      </form>
      {formStatus && <p className="form-status">{formStatus}</p>}
    </div>
  );
};

export default ContactForm;
