import React from 'react';
// import Swiper core and required modules
import { Autoplay } from 'swiper/modules';

import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';

import MotionWrapper from "./MotionWrapper";
import Nes from '../assets/renders/Nes.jpg'; 
import Keyboard from '../assets/renders/Keyboard.jpg'; 
import Atari from '../assets/renders/atari-2600.jpg'; 
import Baubles from '../assets/renders/Baubles.jpg'; 
import Candles from '../assets/renders/Candles.jpg'; 
import Hologram from '../assets/renders/Hologram.jpg'; 
import Mastersystem from '../assets/renders/Mastersystem.jpg'; 

const ThreeD = () => {
    const backgroundImage = "/04.jpg"; // Path relative to the public folder

    const slides = [
        { name: "Keyboard", logo: Keyboard },
        { name: "atari-2600", logo: Atari },
        { name: "Baubles", logo: Baubles },
        { name: "Nes", logo: Nes },
        { name: "Candles", logo: Candles },
        { name: "Hologram", logo: Hologram },
        { name: "Mastersystem", logo: Mastersystem },
    ];

    return (
        <MotionWrapper backgroundImage={backgroundImage}>
            <section className="flex  w-dvw  flex-col items-center justify-center ">
                <article className="flex flex-col gap-4 bg-white/[.7] p-4 pt-20 text-center">
                    <header className="flex flex-col items-center justify-center gap-4 border-b border-b-purple-500 p-4 md:flex-row">
                        <h1 className="text-xl text-purple-500 md:text-4xl">3D Modeling/Rendering/Animations</h1>
                    </header>
                    <section className="flex flex-col flex-wrap gap-4 md:flex-row">
                        <div className="flex flex-1 flex-col items-center">
                            <p>From concept to creation, we deliver exceptional 3D models, renders, and animations. Browse our portfolio to see how we bring products, ideas, and environments to life.</p>
                        </div>
                        <div className="flex flex-1 flex-col items-center">
                            <ul className="mt-2">
                                <li className="text-2xl text-green-500">3D Product Modeling</li>
                                <li className="text-2xl text-teal-500">Architectural Rendering</li>
                                <li className="text-2xl text-orange-500">Animations</li>
                            </ul>
                        </div>
                    </section>
                    <section className="flex max-w-[1920px] flex-wrap gap-4 md:flex-row">
                        <Swiper
                            modules={[Autoplay]}
                            autoplay={{ delay: 5000, disableOnInteraction: false }}
                            loop={true}
                            spaceBetween={0}
                            slidesPerView={1}
                            centeredSlides={true}
                            breakpoints={{
                                320: {
                                    slidesPerView: 1, // For small screens
                                },
                                480: {
                                    slidesPerView: 2, // For slightly larger screens
                                },
                                768: {
                                    slidesPerView: 3, // For tablets
                                },
                                1024: {
                                    slidesPerView: 5, // For desktop
                                },
                            }}
                            className="logos-container w-dvw"
                        >
                            <div className="bg-black p-4">
                                {slides.map((slide, index) => (
                                    <SwiperSlide key={index} className="swiper-3d repeating-border flex flex-col items-center gap-2">
                                        <div className="bg-black p-2">
                                            <img src={slide.logo} alt={slide.name} className="h-auto max-w-full rounded-xl p-2"  />
                                        </div>
                                    </SwiperSlide>
                                ))}
                            </div>
                        </Swiper>
                    </section>
                </article>
            </section>
        </MotionWrapper>
    );
};

export default ThreeD;
