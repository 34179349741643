import React from "react";
import { motion } from "framer-motion";
import "./Loading.css";
import { ReactComponent as Clouds } from "../assets/clouds.svg"; // Assuming you are using create-react-app
import RdLogo from'../assets/logos/Theme-Dark.png';

const Loading = ({ pageTitle }) => {
  return (
    <div className="loading-overlay fixed inset-0 h-dvh">
      <div className="spinner-wrapper h-dvh text-center">
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          transition={{ duration: 1 }}
          className="loading-overlay absolute inset-0 flex h-dvh flex-col"
        >
          <img width="100px" height="100px" src={RdLogo} alt="Ridgy DIdge Ltd Logo"/>
          <p className="loading-text z-50 text-3xl font-bold text-purple-500">Loading {pageTitle}...</p>
        </motion.div>
        {/* Cloud animation */}
        <motion.div
          className="clouds-container absolute inset-0 -z-10"
          initial={{ x: "100dvw" }}
          animate={{ x: "10dvw" }}
          exit={{ x: "100dvw" }}
          transition={{
            duration: 1,
            ease: "easeOut",
            delay: 0.1,
          }}
        >
          <Clouds className="clouds-left absolute inset-y-0 left-0 h-dvh w-dvw" />
        </motion.div>

        <motion.div
          className="clouds-container absolute inset-y-0 right-0 -z-10 h-dvh"
          initial={{ x: "-100dvw", }}
          animate={{ x: "-10dvw" }}
          exit={{ x: "-100dvw" }}
          transition={{
            duration: 1,
            ease: "easeOut",
            delay: 0.3,
          }}
        >
          <Clouds className="clouds-right absolute inset-y-0 right-0 h-dvh w-dvw" />
        </motion.div>
      </div>
    </div>
  );
};

export default Loading;
