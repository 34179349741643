import React from 'react';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from 'chart.js';
import { Bar } from 'react-chartjs-2';

// Register components
ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

const TechSkillsChart = () => {
    const data = {
        labels: ['HTML', 'CSS', 'JavaScript', 'PHP', 'React'],
        datasets: [
            {
                label: 'Proficiency (out of 10)',
                data: [9, 8, 8.5, 7.5, 8],  // Example proficiency (1-10 scale)
                backgroundColor: 'rgba(75, 192, 192, 0.8)',
                borderColor: 'rgba(75, 192, 192, 1)',
                borderWidth: 1,
            },
        ],
    };

    const options = {
        responsive: true,
        animation: {
            duration: 1500, // Duration of the animation (in ms)
            easing: 'easeOut', // Easing function for the animation
        },
        plugins: {
            title: {
                display: true,
                text: 'Web Technologies Mastered',
            },
        },
    };

    return <Bar data={data} options={options} />;
};

export default TechSkillsChart;
