import React, { useState, useEffect } from "react";
import { motion } from "framer-motion";

const MotionWrapper = ({ children, backgroundImage }) => {
  const [imageLoaded, setImageLoaded] = useState(false);
  const [translateDistance, setTranslateDistance] = useState(document.body.scrollHeight);

  // Handle the image load event
  const handleImageLoad = () => {
    setImageLoaded(true);
  };

  // Update translate distance based on the larger value between viewport height and page height
  const updateTranslateDistance = () => {
    const viewportHeight = window.innerHeight;
    const pageHeight = document.documentElement.scrollHeight || document.body.scrollHeight;
    setTranslateDistance(Math.max(viewportHeight, pageHeight));
  };

  useEffect(() => {
    const img = new Image();
    img.src = backgroundImage;
    img.onload = handleImageLoad;

    // Set initial translate distance and update on resize
    updateTranslateDistance();
    window.addEventListener("resize", updateTranslateDistance);

    return () => window.removeEventListener("resize", updateTranslateDistance);
  }, [backgroundImage]);

  return (
    <>
      {imageLoaded && (
        <motion.div
          initial={{ opacity: 1, translateY: -translateDistance }}
          animate={{ opacity: 1, translateY: 0 }}
          exit={{ opacity: 1, translateY: translateDistance }}
          className="z-0 flex items-center"
          transition={{
            duration: 1,
            ease: "easeInOut",
          }}
          style={{
            transformOrigin: "left center",
            transformStyle: "preserve-3d",
            backgroundImage: `url(${backgroundImage})`,
            backgroundAttachment: "fixed",
            backgroundSize: "cover",
            backgroundPosition: "center",
            minHeight: "100dvh", // Full height for the background
          }}
        >
          {children}
        </motion.div>
      )}
      {!imageLoaded && <div>Loading...</div>} {/* Show a loading indicator */}
    </>
  );
};

export default MotionWrapper;
