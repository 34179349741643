import React from 'react';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from 'chart.js';
import { Bar } from 'react-chartjs-2';

// Register components
ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

const ProjectCompletionChart = () => {
    const data = {
        labels: ['Project 1', 'Project 2', 'Project 3', 'Project 4', 'Project 5'],
        datasets: [
            {
                label: 'Completion Time (days)',
                data: [30, 40, 35, 45, 50],  // Example completion time data
                backgroundColor: 'rgba(75, 192, 192, 0.8)',
                borderColor: 'rgba(75, 192, 192, 1)',
                borderWidth: 1,
            },
            {
                label: '% Completed On Time',
                data: [100, 92, 100, 96, 100], // Example on-time completion percentage
                backgroundColor: 'rgba(153, 102, 255, 0.8)',
                borderColor: 'rgba(153, 102, 255, 1)',
                borderWidth: 1,
            }
        ],
    };

    const options = {
        responsive: true,
        animation: {
            duration: 2500, // The duration of the animation in milliseconds
            easing: 'easeOut', // The easing function for animation
        },
        plugins: {
            title: {
                display: true,
                text: 'Project Completion Time & On-Time Completion Rate',
            },
        },
    };

    return <Bar data={data} options={options} />;
};

export default ProjectCompletionChart;
