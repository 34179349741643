import React from "react";
import { Link } from "react-router-dom";
import MotionWrapper from "./MotionWrapper";
import RdLogo from '../assets/logos/Theme-Dark.png';

const HomePage = () => {
  // Define the background image URL
  const backgroundImage = "/00.jpg"; // Path relative to the public folder

  // Array of content for sections
  const sections = [
    {
      title: "Website Development",
      description1: "Crafting Stunning Websites, Immersive 3D Experiences & Innovative Software Solutions.",
      description2: "We design and build high-quality, responsive websites tailored to your needs, from simple landing pages to fully-functional eCommerce platforms.",
    },
    {
      title: "3D Modeling & Rendering",
      description1: "We design, build, and animate websites that captivate and convert.",
      description2: "Our 3D modeling and rendering services bring your ideas to life with unmatched precision and detail, ideal for product designs, architectural visuals, and more.",
    },
    {
      title: "Programming Services",
      description1: "Versatile software programming, we create tailored solutions that bring ideas to life.",
    },
  ];

  return (
    <MotionWrapper backgroundImage={backgroundImage}>
      <section className="flex w-dvw flex-col items-center justify-center">
        <div className="flex max-w-[1920px] flex-col gap-4 p-4 pt-20 text-center">
          <header className="flex flex-col items-center justify-center gap-4 border-b border-b-purple-500 p-4 md:flex-row">
            <img width="100px" height="100px" src={RdLogo} alt="Ridgy Didge Ltd Logo" />
            <h1 className="font-arial text-xl text-white md:text-4xl">Ridgy Didge<span className="text-6xl text-red-500">.</span></h1>
          </header>
          <article>
            <div className="flex flex-1 flex-col gap-4 bg-black/[0.5] lg:flex-row">
              {sections.map((section, index) => (
                <section key={index} className="flex flex-1 flex-col gap-4 p-4 text-white">
                  <header>
                    <h2 className=" font-arial text-2xl text-white">{section.title}<span className="text-4xl text-red-500">.</span></h2>
                  </header>
                  <p className="text-xl">{section.description1}</p>
                  <p>{section.description2}</p>
                </section>
              ))}
            </div>
          </article>
          <nav className="flex justify-center gap-2">
            <Link className="btn btn-primary btn-hover-animate rounded-full" to="/contact">
              Get a Free Quote
            </Link>
            <Link className="btn btn-primary btn-hover-animate rounded-full" to="/previouswork">
              View Our Work
            </Link>
          </nav>
        </div>
      </section>
    </MotionWrapper>
  );
};

export default HomePage;
