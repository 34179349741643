import React from "react";
import MotionWrapper from "./MotionWrapper";

function About() {
  // Define the background image URL
  const backgroundImage = "/01.jpg"; // Path relative to the public folder

  return (
    <MotionWrapper backgroundImage={backgroundImage}>
      <section className="flex w-dvw items-center justify-center">
        <article className="flex max-w-[1920px] flex-col gap-4 bg-white/[.7] p-4 pt-20 text-center">
          <header className="flex flex-col items-center justify-center gap-4 border-b border-b-purple-500 p-4 md:flex-row">
            <h1 className="text-xl text-purple-500 md:text-4xl">About</h1>
          </header>
          <section>
            <p className="">
              Ridgy Didge Ltd specializes in crafting exceptional digital solutions. With a focus on innovative web development, immersive 3D experiences, and versatile software solutions, we bring your ideas to life.
            </p>
          </section>
        </article>
      </section>
    </MotionWrapper>
  );
}

export default About;
