import React from 'react';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Pie } from 'react-chartjs-2';

// Register components
ChartJS.register(ArcElement, Tooltip, Legend);

const TechStackChart = () => {
    const data = {
        labels: ['WordPress', 'React', 'WooCommerce', 'Other Platforms'],
        datasets: [
            {
                label: 'Platform Usage',
                data: [65, 15, 5, 15], // Example percentage breakdown
                backgroundColor: [
                    'rgba(75, 192, 192, 0.8)',
                    'rgba(153, 102, 255, 0.8)',
                    'rgba(255, 159, 64, 0.8)',
                    'rgba(255, 99, 132, 0.8)',
                ],
                borderColor: [
                    'rgba(75, 192, 192, 1)',
                    'rgba(153, 102, 255, 1)',
                    'rgba(255, 159, 64, 1)',
                    'rgba(255, 99, 132, 1)',
                ],
                borderWidth: 1,
            },
        ],
    };

    const options = {
        responsive: true,
        animation: {
            animateRotate: true,  // Rotate animation
            animateScale: true,   // Scale animation to grow sections
            duration: 2000,       // Animation duration (in ms)
            easing: 'easeOut',  // Easing function
        },
        plugins: {
            title: {
                display: true,
                text: 'Percentage Breakdown of Projects by Platform',
            },
            legend: {
                position: 'top',
            },
        },
    };

    return <Pie data={data} options={options} />;
};

export default TechStackChart;
