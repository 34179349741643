import React, { useState, useEffect } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  NavLink,
  useLocation,
} from "react-router-dom";
import { AnimatePresence, motion } from "framer-motion";
import RdLogo from'./assets/logos/Theme-Dark.png';
import Home from "./components/Home";
import About from "./components/About";
import Work from "./components/PreviousWork";
import Services from "./components/Services";
import Contact from "./components/Contact";
import Loading from "./components/Loading";
import StatsPage from "./components/StatsPage";
import ThreeD from "./components/ThreeD";
import "./App.css";

function AnimatedRoutes({ setLoading, setPageTitle }) {
  const location = useLocation();

  useEffect(() => {
    const pathToTitle = {
      "/": "Home",
      "/about": "About",
      "/previouswork": "Previous Work",
      "/services": "Services",
      "/contact": "Contact",
      "/statspage": "Stats Page",
      "/3d": "3D",
    };

    const currentTitle = pathToTitle[location.pathname] || "Page";
    setPageTitle(currentTitle);

    setLoading(true);
    const timeout = setTimeout(() => setLoading(false), 1000);
    return () => clearTimeout(timeout);
  }, [location.pathname, setLoading, setPageTitle]);

  return (
    <AnimatePresence mode="wait">
      <Routes location={location} key={location.pathname}>
        <Route path="/" element={<Home />} />
        <Route path="/about" element={<About />} />
        <Route path="/previouswork" element={<Work />} />
        <Route path="/services" element={<Services />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/statspage" element={<StatsPage />} />
        <Route path="/3d" element={<ThreeD />} />
      </Routes>
    </AnimatePresence>
  );
}

function App() {
  const [loading, setLoading] = useState(false);
  const [pageTitle, setPageTitle] = useState("");
  const [menuOpen, setMenuOpen] = useState(false);

  // Automatically show the menu for tablet and larger screens
  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth >= 768) {
        setMenuOpen(true);
      }
    };

    handleResize(); // Initialize on mount
    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <div className="Apps">
      <Router>
        <div className="flex flex-col bg-green-200 text-black md:flex-row">
          {/* Menu Toggle Button (Hidden on Tablet and Above) */}
          <button
            className="fixed right-4 top-4 z-50 rounded bg-black p-2 text-white md:hidden"
            onClick={() => setMenuOpen(!menuOpen)}
          >
            ☰
          </button>

          {/* Sidebar Navigation */}
          <motion.div
            initial={{ y: "-100%" }}
            animate={{
              y: menuOpen || window.innerWidth >= 768 ? 0 : "-100%",
            }}
            transition={{ duration: 0.5 }}
            className="fixed left-0 top-0 z-40 flex w-full flex-col items-center justify-center md:flex-row lg:translate-y-0"
          >
            <header className="p-2">
              <img
                width="75px"
                height="75px"
                src={RdLogo}
                alt="Ridgy Didge Ltd Logo"
              />
            </header>
            <nav className="w-full p-4 md:w-auto">
              <ul className="flex w-full flex-col flex-wrap items-center justify-center gap-4 md:w-auto md:flex-row">
                <li className="flex w-full justify-center md:w-auto">
                  <NavLink
                    exact
                    className="btn btn-nav w-full md:w-auto"
                    to="/"
                    onClick={() => setMenuOpen(false)}
                  >
                    Home
                  </NavLink>
                </li>
                <li className="flex w-full justify-center md:w-auto">
                  <NavLink
                    className="btn btn-nav w-full md:w-auto"
                    to="/about"
                    onClick={() => setMenuOpen(false)}
                  >
                    About
                  </NavLink>
                </li>
                <li className="flex w-full justify-center md:w-auto">
                  <NavLink
                    className="btn btn-nav w-full md:w-auto"
                    to="/previouswork"
                    onClick={() => setMenuOpen(false)}
                  >
                    Previous Work
                  </NavLink>
                </li>
                <li className="flex w-full justify-center md:w-auto">
                  <NavLink
                    className="btn btn-nav w-full md:w-auto"
                    to="/services"
                    onClick={() => setMenuOpen(false)}
                  >
                    Services
                  </NavLink>
                </li>
                <li className="flex w-full justify-center md:w-auto">
                  <NavLink
                    className="btn btn-nav w-full md:w-auto"
                    to="/contact"
                    onClick={() => setMenuOpen(false)}
                  >
                    Contact
                  </NavLink>
                </li>
                <li className="flex w-full justify-center md:w-auto">
                  <NavLink
                    className="btn btn-nav w-full md:w-auto"
                    to="/statspage"
                    onClick={() => setMenuOpen(false)}
                  >
                    Stats
                  </NavLink>
                </li>
                <li className="flex w-full justify-center md:w-auto">
                  <NavLink
                    className="btn btn-nav w-full md:w-auto"
                    to="/3d"
                    onClick={() => setMenuOpen(false)}
                  >
                    3D
                  </NavLink>
                </li>
              </ul>
            </nav>
          </motion.div>
          <motion.div className="relative flex w-dvw flex-1 items-center justify-center overflow-hidden">
            {/* Show loading animation */}
            <AnimatePresence>{loading && <Loading pageTitle={pageTitle} />}</AnimatePresence>
            {/* Render Animated Routes */}
            <AnimatedRoutes setLoading={setLoading} setPageTitle={setPageTitle} />
          </motion.div>
        </div>
      </Router>

    </div>
  );
}

export default App;
