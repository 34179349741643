import React from "react";
// import Swiper core and required modules
import { Autoplay } from 'swiper/modules';

import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/scrollbar';

import MotionWrapper from "./MotionWrapper";
import UOP from "../assets/logos/250x250-portsmoutn-uni-logo.png"; 
import Benefex from "../assets/logos/250x250-benefex-b.png"; 
import UOS from "../assets/logos/250x250-UniversityOfSouthampton-OK.jpg"; 
import Cytoplan from "../assets/logos/250x250-cytoplan.png"; 
import Vodfone from "../assets/logos/250x250-Vodafone_icon.svg.png";
import BT from "../assets/logos/BT_logo_2019.svg_-300x300.png";
import SixB from "../assets/logos/6b.png";
import NHS from "../assets/logos/nhs-300x122.png";

function PreviousWork() {
  const backgroundImage = "/03.jpg"; // Path relative to the public folder

  const slides = [
    { name: "University of Portsmouth", logo: UOP },
    { name: "Benefex", logo: Benefex },
    { name: "University of Southampton", logo: UOS },
    { name: "Cytoplan", logo: Cytoplan },
    { name: "Vodfone", logo: Vodfone },
    { name: "BT", logo: BT },
    { name: "6B", logo: SixB },
    { name: "NHS", logo: NHS },
  ];

  return (
    <MotionWrapper backgroundImage={backgroundImage}>
      <section className="flex w-dvw flex-col items-center justify-center gap-2">
        <article className="flex max-w-[1920px] flex-col gap-4 bg-white/[.5] p-4 pt-20 text-center">
          <header className="flex flex-col items-center justify-center gap-4 border-b border-b-purple-500 p-4 md:flex-row">
            <h1 className="text-xl text-purple-500 md:text-4xl">Previous Work</h1>
          </header>
          <section className="flex max-w-[1920px] flex-wrap gap-4 md:flex-row">
            <p>Trusted by Leading Brands & Institutions.
            We have had the privilege of working with some of the most respected names in the industry, including UK universities, leading mobile telecom companies, and eCommerce powerhouses.</p>
            <Swiper
              modules={[Autoplay]}
              autoplay={{ delay: 3000, disableOnInteraction: true }}
              loop={true}
              spaceBetween={0}
              slidesPerView={1}
              centeredSlides={true}
              breakpoints={{
                320: {
                  slidesPerView: 1, // For small screens
                  spaceBetween: 10,
                },
                480: {
                  slidesPerView: 2, // For slightly larger screens
                  spaceBetween: 15,
                },
                768: {
                  slidesPerView: 3, // For tablets
                  spaceBetween: 20,
                },
                1024: {
                  slidesPerView: 5, // For desktop
                  spaceBetween: 20,
                },
                1480: {
                  slidesPerView: 6, // For desktop
                  spaceBetween: 20,
                },
              }}
              className="logos-container w-dvw"
            >
              {slides.map((slide, index) => (
                <SwiperSlide key={index} className="flex flex-col items-center gap-2">
                  <h3>{slide.name}</h3>
                  <img src={slide.logo} alt={slide.name} className="max-h-[100px]" />
                </SwiperSlide>
              ))}
            </Swiper>
          </section>
        </article>
      </section>
    </MotionWrapper>
  );
}

export default PreviousWork;
